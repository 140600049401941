@use '../core/core' as core;

.ax-button {
    @include core.typography-heading-base;

    display: flex;
    padding: .25rem 1rem;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    column-gap: .5rem;
    transition: all .25s linear;
    height: 34px;
    max-height: 34px;
    box-sizing: border-box;
    width: fit-content;
    background-color: transparent;
    outline: 0;
    border: none;
    background-color: var(--ax__button__background__color);
    border-radius: .25rem;

    &:hover {
        background-color: var(--ax__button__background__color__hover);
    }

    &:active {
        background-color: var(--ax__button__background__color__active);
    }

    &:focus-visible {
        background-color: var(--ax__button__background__color__hover);
    }

    &:disabled {
        pointer-events: none;
        opacity: .5;
    }

    &--primary {
        background-color: var(--ax__button__background__color__primary);

        .ax-button__label {
            color: var(--ax__button__text__color__primary);
        }

        .ax-button__icon {
            color: var(--ax__button__text__color__primary);
        }

        &:hover {
            background-color: var(--ax__button__background__color__primary__hover);
        }

        &:active {
            background-color: var(--ax__button__background__color__primary__active);
        }

        &:focus-visible {
            background-color: var(--ax__button__background__color__primary__hover);
        }
    }

    &--danger {
        background-color: var(--ax__button__background__color__danger);

        .ax-button__label {
            color: var(--ax__button__text__color__danger);
        }

        .ax-button__icon {
            color: var(--ax__button__text__color__danger);
        }

        &:hover {
            background-color: var(--ax__button__background__color__danger__hover);
        }

        &:active {
            background-color: var(--ax__button__background__color__danger__active);
        }

        &:focus-visible {
            background-color: var(--ax__button__background__color__danger__hover);
        }
    }

    &--icon-only {
        padding: .5rem;
        height: fit-content;
        background-color: transparent;
    }
}