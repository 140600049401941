@use './colors' as color;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap');

@mixin heading-base {
    display: block;
    margin: 0;
    font-family: 'Exo 2', sans-serif;
    font-style: normal;
    font-weight: 600;
    color: var(--ax__text__color);
}

@mixin base {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: var(--ax__text__color);
}

@mixin setup-core-typography() {
    body {
        @include base;
    }

    h1, h2, h3, h4, h5, h6 {
        @include heading-base;
    }
    
    a {
        cursor: pointer;
        color: var(--ax__anchor__text__color);

        &:hover {
            text-decoration: underline;
        }
    }
}