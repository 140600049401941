@use './core/core' as core;

@import './themes/light';
@import './themes/dark';

@import './components/button';
@import './components/input';
@import './components/toast';
 
@include core.setup();

body {
    margin: 0;
    padding: 0;
    background-color: var(--ax__background__color);
    -webkit-tap-highlight-color: transparent;
}