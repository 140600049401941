@use '../core/core' as core;

:root {
    // Core
    --ax__background__color: #{core.$color-background};
    --ax__text__color: #{core.$color-text-color};
    --ax__anchor__text__color: #{core.$color-info};
    --ax__container__background__color: #{core.$color-container-background};
    --ax__border__color: #{color-mix(in srgb, core.$color-background-dark, transparent 70%)};
    --ax__border__color__darker: #{color-mix(in srgb, core.$color-background-dark, transparent 50%)};
    --ax__backdrop__color: #{color-mix(in srgb, darken(core.$color-background-dark, 5%), transparent 30%)};
    --ax__box__shadow__right: #{2px 2px 5px 0px lighten(core.$color-background-dark, 40%)};
    --ax__box__shadow__left: #{-2px -2px 5px 0px lighten(core.$color-background-dark, 40%)};

    // Buttons
    --ax__button__background__color: #{darken(core.$color-container-background, 10%)};
    --ax__button__background__color__hover: #{darken(core.$color-container-background, 15%)};
    --ax__button__background__color__active: #{darken(core.$color-container-background, 20%)};

    --ax__button__text__color__primary: #{core.$color-text-color-dark};
    --ax__button__background__color__primary: #{core.$color-primary};
    --ax__button__background__color__primary__hover: #{lighten(core.$color-primary, 10%)};
    --ax__button__background__color__primary__active: #{lighten(core.$color-primary, 20%)};

    --ax__button__text__color__danger: #{core.$color-text-color-dark};
    --ax__button__background__color__danger: #{core.$color-danger};
    --ax__button__background__color__danger__hover: #{lighten(core.$color-danger, 10%)};
    --ax__button__background__color__danger__active: #{lighten(core.$color-danger, 15%)};

    // Form elements (Input, select, etc)
    --ax__form__background__color: #{darken(core.$color-background, 2%)};
    --ax__form__border__color: #{darken(core.$color-container-background, 25%)};
    --ax__form__border__focus: #{core.$color-primary};
    --ax__form__border__invalid: #{core.$color-danger};
    --ax__form__placeholder__color: #{color-mix(in srgb, core.$color-text-color, transparent 70%)};

    // Toast
    --ax__toast__text__color: #{core.$color-text-color-dark};

    // Tags
    --ax__tag__background__color: #{darken(core.$color-container-background, 5%)};

    // Checkbox
    --ax__checkbox__icon__color: #{core.$color-text-color-dark};

    // Select
    --ax__select__option__selected: #{core.$color-primary};
    --ax__select__option__hover: #{lighten(core.$color-primary, 5%)};
    --ax__select__option__text__color: #{core.$color-text-color-dark};

    // Avatar
    --ax__avatar__background__color: #{darken(core.$color-container-background, 10%)};
    --ax__avatar__text__color: #{core.$color-text-color};

    // Toggle button
    --ax__toggle__button__toggle__background__color: #{darken(core.$color-background, 10%)};
    --ax__toggle__button__checked__background__color: #{core.$color-primary};
    --ax__toggle__button__icon__color: #{core.$color-text-color};

    // Tab Container / Tabs
    --ax__tab__background__color: #{darken(core.$color-background, 5%)};
    --ax__tab__background__color__hover: #{core.$color-background};
    --ax__tab__selected__mobile__background__color: #{core.$color-primary};
    --ax__tab__selected__mobile__text__color: #{core.$color-text-color-dark};

    // Table
    --ax__table__header__background__color: #{darken(core.$color-background, 8%)};
    --ax__table__row__hover: #{darken(core.$color-background, 3%)};
    --ax__table__row__selected: #{darken(core.$color-background, 5%)};

    // Specific page: TC-registration Shipment Boxrow Status
    --ax__box-row__status__1: #{color-mix(in srgb, core.$color-warning, transparent 60%)};
    --ax__box-row__status__2: #{color-mix(in srgb, core.$color-success, transparent 60%)};
}