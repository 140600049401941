@use '../core/core' as core;

.ax-toast__component__container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    position: absolute;
    overflow-y: hidden;
    padding: .25rem;

    &--bottom-right {
        bottom: 1rem;
        right: 1rem;
        left: 1rem;

        @include core.media-tablet-up {
            left: unset;
        }
    }

    &--bottom-left {
        bottom: 1rem;
        left: 1rem;
        right: 1rem;

        @include core.media-tablet-up {
            right: unset;
        }
    }

    &--top-right {
        top: 1rem;
        right: 1rem;
        left: 1rem;

        @include core.media-tablet-up {
            left: unset;
        }
    }

    &--top-left {
        top: 1rem;
        left: 1rem;

        @include core.media-tablet-up {
            right: unset;
        }
    }
}