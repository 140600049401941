@use '../core/core' as core;

.ax-input {
    @include core.typography-base;

    display: flex;
    flex: 1 1 auto;
    padding: .25rem .75rem;
    border: 1px solid var(--ax__form__border__color);
    border-radius: .25rem;
    background-color: var(--ax__form__background__color);
    color: var(--ax__text__color);
    height: 34px;
    outline: 0;
    min-width: 80px;
    box-sizing: border-box;
    font-size: 16px;

    &::placeholder {
        color: var(--ax__form__placeholder__color);
    }

    &:focus-visible {
        outline: var(--ax__form__border__focus) solid 1px;
        border-color: var(--ax__form__border__focus);
    }

    &:disabled {
        pointer-events: none;
        opacity: .5;
    }

    &--invalid {
        border-color: var(--ax__form__border__invalid);
    }
}

input[type=number] { 
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0; 
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
}
